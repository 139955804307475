import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};
    background: url('/images/arch-dark.png') center center / cover no-repeat;
    // background-size: cover;
    mim-height: 100vh;
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
